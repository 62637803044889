<template>
  <div class="home-body">
    <Hero></Hero>

    <!-- <AboutUs></AboutUs> -->

    <!-- <StepsToRegisterVehicle></StepsToRegisterVehicle> -->

    <!-- <CompanyExpectations></CompanyExpectations> -->

    <CompanyStats></CompanyStats>

    <Callout></Callout>

    <ContactDetails></ContactDetails>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'Home',
  rendered() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
  components: {
    Hero: () => import('./components/Hero.vue'),
   // HeroTesla: () => import('./components/HeroTesla.vue'),
    // AboutUs: () => import('./components/AboutUs.vue'),
    // CompanyExpectations: () => import('./components/CompanyExpectations.vue'),
    //StepsToRegisterVehicle: () => import('./components/StepsToRegisterVehicle.vue'),
    CompanyStats: () => import('./components/CompanyStats.vue'),
    Callout: () => import('./components/Callout.vue'),
    ContactDetails: () => import('./components/ContactDetails.vue'),
  },
  data: () => ({}),
};
</script>

<style scoped>
.home-body {
  overflow-x: hidden;
}
</style>
